import React from 'react'
import BackgroundImage from 'gatsby-background-image'

const PageContainer = ({ hero, children }) => {
  return (
    hero ? (
      <BackgroundImage
        Tag="main"
        className='page_container'
        fluid={hero}
      >
        {children}
      </BackgroundImage>
    ) : children
  )
}

export default PageContainer