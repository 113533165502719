import React from 'react'
import { Link } from 'gatsby'
import checkatrade from '../images/checkatrade.png'

const Footer = () => {
  return (
    <footer className='space-between'>
      <div>
        &copy; 2021 Brondesbury Interiors <span className='divider'>|</span> <Link to='/policy'>Policy</Link>
      </div>
      <a href='https://www.checkatrade.com/trades/AndyAndSonsDecorators#gallery' target='_blank' rel="noreferrer">
        <img src={checkatrade} alt='Brondesbury Interiors at CheckaTrade' />
      </a>
    </footer>
  )
}

export default Footer