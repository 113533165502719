import React from 'react'
import Header from './Header'
import Footer from '../Footer'

const NavContainer = ({ children }) => {
  return (
    <div className='nav_container'>
      <Header />
      <div dangerouslySetInnerHTML={{ __html: children }} />
      <div className='footer-dtop'>
        <Footer />
      </div>
    </div>
  )
}

export default NavContainer