import React from "react"
import NavContainer from "./NavContainer/NavContainer"
import PageContainer from "./PageContainer"
import Footer from "./Footer"
import '../styles.scss'

const Layout = ({ imageData, content, children }) => {
  return (
    <>
      <div className='site_wrapper'>
        <PageContainer hero={imageData}>
          {children}
        </PageContainer>
        <NavContainer>
          {content}
        </NavContainer>
      </div>
      <div className='footer-mob'>
        <Footer />
      </div>
    </>
  )
}

export default Layout